<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>FAQ</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><span></span></li>
                <li>FAQ</li>
            </ul>
        </div>
    </div>
</div>

<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>Common Questions</h2>
                </div>

                <div class="faq-item">
                    <ul class="accordion">
                        <li>
                            <a>CAN I DOWNLOAD MATERIALS FROM YOUR WEBSITE?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?</p>
                        </li>
                        <li>
                            <a>HOW DO I SUBMIT IDEAS OR SCRIPTS TO BE CONSIDERED?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?</p>
                        </li>
                        <li>
                            <a>HOW DO I GET TICKETS TO MOVIE PREMIERES?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?</p>
                        </li>
                        <li>
                            <a>WHEN DOES A CERTAIN MOVIE COME OUT ON DVD?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?</p>
                        </li>
                        <li>
                            <a>HOW DO I ACCESS THE EDGE PICTURES WEB SITE?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>Movie</h2>
                </div>

                <div class="faq-item">
                    <ul class="accordion">
                        <li>
                            <a>HOW DO I ACCESS THE PICTURES IN WEBSITE?</a>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut fugiat, expedita vero et quia saepe debitis exercitationem labore consequatur fuga ab, laudantium sed maiores velit reiciendis. Ullam illo laborum sequi.</p>
                        </li>
                        <li>
                            <a>WHEN DOES A CERTAIN MOVIE COME OUT ON DVD?</a>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint natus ipsa accusantium saepe dolorem a mollitia tempora consequatur totam modi pariatur nulla, aspernatur dolore consequuntur temporibus unde minima repellendus laboriosam.</p>
                        </li>
                        <li>
                            <a>HOW CAN I CONTACT A CERTAIN ACTOR/ACTRESS OR FILMMAKER?	</a>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint natus ipsa accusantium saepe dolorem a mollitia tempora consequatur totam modi pariatur nulla, aspernatur dolore consequuntur temporibus unde minima repellendus laboriosam.</p>
                        </li>
                        <li>
                            <a>HOW DO I SUBMIT IDEAS OR SCRIPTS TO BE CONSIDERED?	</a>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint natus ipsa accusantium saepe dolorem a mollitia tempora consequatur totam modi pariatur nulla, aspernatur dolore consequuntur temporibus unde minima repellendus laboriosam.</p>
                        </li>
                        <li>
                            <a>HOW CAN I GET SEAT ON HALL</a>
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint natus ipsa accusantium saepe dolorem a mollitia tempora consequatur totam modi pariatur nulla, aspernatur dolore consequuntur temporibus unde minima repellendus laboriosam.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>Short Film</h2>
                </div>
                
                <div class="faq-item">
                    <ul class="accordion">
                        <li>
                            <a>WHAT IS SHORT FILM?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?</p>
                        </li>
                        <li>
                            <a>HOW CAN I GET IN WEBSITE?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?</p>
                        </li>
                        <li>
                            <a>HOW TO MAKE A SHORT FILM?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?</p>
                        </li>
                        <li>
                            <a>HOW MANY PROCESSES HAVE FOR SHORT FILM?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?</p>
                        </li>
                        <li>
                            <a>HOW CAN I BUY TICKET?</a>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>