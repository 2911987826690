<footer class="pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a class="footer-logo-img" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aviverra</p>
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-instagram">
                        <h3>Instagram</h3>

                        <ul>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/instagram1.jpg" alt="Instagram"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/instagram2.jpg" alt="Instagram"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/instagram3.jpg" alt="Instagram"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/instagram4.jpg" alt="Instagram"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/instagram5.jpg" alt="Instagram"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/instagram6.jpg" alt="Instagram"></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-link">
                        <h3>Useful Links</h3>

                        <ul>
                            <li><a routerLink="/">Home</a></li>
                            <li><a routerLink="/about">About</a></li>
                            <li><a routerLink="/works">Works</a></li>
                            <li><a routerLink="/videos">Videos</a></li>
                            <li><a routerLink="/blog">Blog</a></li>
                            <li><a routerLink="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-touch">
                        <h3>Get In Touch</h3>

                        <ul>
                            <li>
                                <i class='bx bxs-phone-call'></i>
                                <h4>Phone:</h4>
                                <a href="tel:+882569756">+882-569-756</a>
                            </li>
                            <li>
                                <i class='bx bx-message-square-detail'></i>
                                <h4>Email:</h4>
                                <a href="mailto:info@zico.com">info@zico.com</a>
                            </li>
                            <li>
                                <i class='bx bxs-location-plus'></i>
                                <h4>Address:</h4>
                                <span>3254-425 NW-2nd Ave, Miami USA</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="copyright-area">
    <div class="container">
        <div class="copyright-item">
            <p>© Zico is Proudly Owned by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
        </div>
    </div>
</div>