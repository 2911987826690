<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Videos</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><span></span></li>
                <li>Videos</li>
            </ul>
        </div>
    </div>
</div>

<div class="week-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="week-item week-video-btn-two">
                    <img src="assets/img/home-one/week4.jpg" alt="Week">
                    <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube"><i class='bx bx-play'></i></a>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-4">
                <div class="week-item week-video-btn-two">
                    <img src="assets/img/home-one/week5.jpg" alt="Week">
                    <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube"><i class='bx bx-play'></i></a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="week-item week-video-btn-two">
                    <img src="assets/img/home-one/week6.jpg" alt="Week">
                    <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube"><i class='bx bx-play'></i></a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="week-item week-video-btn-two">
                    <img src="assets/img/home-one/week7.jpg" alt="Week">
                    <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube"><i class='bx bx-play'></i></a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="week-item week-video-btn-two">
                    <img src="assets/img/home-one/week8.jpg" alt="Week">
                    <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube"><i class='bx bx-play'></i></a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="week-item week-video-btn-two">
                    <img src="assets/img/home-one/week9.jpg" alt="Week">
                    <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube"><i class='bx bx-play'></i></a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="week-item week-video-btn-two">
                    <img src="assets/img/home-one/week10.jpg" alt="Week">
                    <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube"><i class='bx bx-play'></i></a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="week-item week-video-btn-two">
                    <img src="assets/img/home-one/week11.jpg" alt="Week">
                    <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube"><i class='bx bx-play'></i></a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="week-item week-video-btn-two">
                    <img src="assets/img/home-one/week12.jpg" alt="Week">
                    <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube"><i class='bx bx-play'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>