<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Pricing Plans</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><span></span></li>
                <li>Pricing Plans</li>
            </ul>
        </div>
    </div>
</div>

<section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="pricing-item">
                    <div class="pricing-top">
                        <span>$</span>
                        <h3>10</h3>
                        <p>Weekly</p>
                    </div>

                    <div class="pricing-bottom">
                        <ul>
                            <li>Unlimited Bandwidth</li>
                            <li>200GB Space</li>
                            <li>24/7 support</li>
                            <li><del>Security</del></li>
                            <li><del>Admin Controls</del></li>
                        </ul>

                        <div class="price-btn">
                            <a routerLink="/pricing">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="pricing-item pricing-transform">
                    <div class="pricing-top">
                        <span>$</span>
                        <h3>20</h3>
                        <p>Monthly</p>
                    </div>

                    <div class="pricing-bottom">
                        <ul>
                            <li>Unlimited Bandwidth</li>
                            <li>200GB Space</li>
                            <li>24/7 support</li>
                            <li>Security</li>
                            <li><del>Admin Controls</del></li>
                        </ul>

                        <div class="price-btn">
                            <a routerLink="/pricing">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="pricing-item">
                    <div class="pricing-top">
                        <span>$</span>
                        <h3>30</h3>
                        <p>Yearly</p>
                    </div>

                    <div class="pricing-bottom">
                        <ul>
                            <li>Unlimited Bandwidth</li>
                            <li>200GB Space</li>
                            <li>24/7 support</li>
                            <li>Security</li>
                            <li>Admin Controls</li>
                        </ul>

                        <div class="price-btn">
                            <a routerLink="/pricing">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>