<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Partners</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><span></span></li>
                <li>Partners</li>
            </ul>
        </div>
    </div>
</div>

<div class="partner-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-4 col-lg-3">
                <div class="partner-item">
                    <img src="assets/img/brand1.png" alt="Partner">
                </div>
            </div>

            <div class="col-sm-4 col-lg-3">
                <div class="partner-item">
                    <img src="assets/img/brand2.png" alt="Partner">
                </div>
            </div>

            <div class="col-sm-4 col-lg-3">
                <div class="partner-item">
                    <img src="assets/img/brand3.png" alt="Partner">
                </div>
            </div>

            <div class="col-sm-4 col-lg-3">
                <div class="partner-item">
                    <img src="assets/img/brand4.png" alt="Partner">
                </div>
            </div>

            <div class="col-sm-4 col-lg-3">
                <div class="partner-item">
                    <img src="assets/img/brand5.png" alt="Partner">
                </div>
            </div>
            
            <div class="col-sm-4 col-lg-3">
                <div class="partner-item">
                    <img src="assets/img/brand6.png" alt="Partner">
                </div>
            </div>

            <div class="col-sm-4 col-lg-3">
                <div class="partner-item">
                    <img src="assets/img/brand7.png" alt="Partner">
                </div>
            </div>
            
            <div class="col-sm-4 col-lg-3">
                <div class="partner-item">
                    <img src="assets/img/brand8.png" alt="Partner">
                </div>
            </div>
        </div>
    </div>
</div>