import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { FeaturesComponent } from './components/pages/features/features.component';
import { TeamComponent } from './components/pages/team/team.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { PartnerComponent } from './components/pages/partner/partner.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { TimelineComponent } from './components/pages/timeline/timeline.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { AboutMeComponent } from './components/pages/about-me/about-me.component';
import { WorksComponent } from './components/pages/works/works.component';
import { WorksDetailsComponent } from './components/pages/works-details/works-details.component';
import { VideosComponent } from './components/pages/videos/videos.component';
import { VideosDetailsComponent } from './components/pages/videos-details/videos-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';

const routes: Routes = [
    {path: '', component: HomeOneComponent},
    {path: 'home-two', component: HomeTwoComponent},
    {path: 'home-three', component: HomeThreeComponent},
    {path: 'features', component: FeaturesComponent},
    {path: 'team', component: TeamComponent},
    {path: 'testimonials', component: TestimonialsComponent},
    {path: 'partner', component: PartnerComponent},
    {path: 'pricing', component: PricingComponent},
    {path: 'timeline', component: TimelineComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'terms-conditions', component: TermsConditionsComponent},
    {path: 'about-us', component: AboutUsComponent},
    {path: 'about-me', component: AboutMeComponent},
    {path: 'works', component: WorksComponent},
    {path: 'works-details', component: WorksDetailsComponent},
    {path: 'videos', component: VideosComponent},
    {path: 'videos-details', component: VideosDetailsComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'contact', component: ContactComponent},

    {path: '**', component: ErrorComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }