<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Timeline</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><span></span></li>
                <li>Timeline</li>
            </ul>
        </div>
    </div>
</div>

<div class="history-area pt-100 pb-70">
    <div class="container-fluid p-0">
        <div class="cd-horizontal-timeline">
            <div class="section-title">
                <span class="sub-title">History</span>
                <h2>Our History</h2>
            </div>

            <div class="timeline">
                <div class="events-wrapper">
                    <div class="events">
                        <ul>
                            <li><a href="#0" data-date="01/01/2005" class="selected">1980</a></li>
                            <li><a href="#0" data-date="01/01/2007">1995</a></li>
                            <li><a href="#0" data-date="01/01/2011">2000</a></li>
                            <li><a href="#0" data-date="01/01/2012">2005</a></li>
                            <li><a href="#0" data-date="01/01/2013">2015</a></li>
                        </ul>
                        <span class="filling-line" aria-hidden="true"></span>
                    </div>
                </div>
                <ul class="cd-timeline-navigation">
                    <li><i class='bx bx-chevron-left'></i></li>
                    <li><i class='bx bx-chevron-right'></i></li>
                </ul>
            </div>
        
            <div class="events-content">
                <ul>
                    <li class="selected" data-date="01/01/2005">
                        <div class="row align-items-center m-0">
                            <div class="col-lg-5 col-md-12">
                                <div class="history-img">
                                    <img src="assets/img/home-one/history-main.jpg" alt="History">
                                </div>
                            </div>

                            <div class="col-lg-7 col-md-12">
                                <div class="history-content">
                                    <h2>Let’s make great things together!</h2>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                    <div class="row">
                                        <div class="col-sm-6 col-lg-4">
                                            <div class="history-inner">
                                                <i class='flaticon-video-camera'></i>
                                                <h3>FILM  PRODuction</h3>
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</span>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 col-lg-4">
                                            <div class="history-inner">
                                                <i class='flaticon-creative-idea'></i>
                                                <h3>Creative Direction</h3>
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</span>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                                            <div class="history-inner">
                                                <i class='flaticon-eye'></i>
                                                <h3>Visual Effects</h3>
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
        
                    <li data-date="01/01/2007">
                        <div class="row align-items-center m-0">
                            <div class="col-lg-5 col-md-12">
                                <div class="history-img">
                                    <img src="assets/img/home-one/history-main.jpg" alt="History">
                                </div>
                            </div>

                            <div class="col-lg-7 col-md-12">
                                <div class="history-content">
                                    <h2>Let’s  make  great  things  together!</h2>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                    <div class="row">
                                        <div class="col-sm-4 col-lg-4">
                                            <div class="history-inner">
                                                <i class='flaticon-video-camera'></i>
                                                <h3>FILM  PRODuction</h3>
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</span>
                                            </div>
                                        </div>

                                        <div class="col-sm-4 col-lg-4">
                                            <div class="history-inner">
                                                <i class='flaticon-creative-idea'></i>
                                                <h3>Creative Direction</h3>
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</span>
                                            </div>
                                        </div>

                                        <div class="col-sm-4 col-lg-4">
                                            <div class="history-inner">
                                                <i class='flaticon-eye'></i>
                                                <h3>Visual Effects</h3>
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
        
                    <li data-date="01/01/2011">
                        <div class="row align-items-center m-0">
                            <div class="col-lg-5 col-md-12">
                                <div class="history-img">
                                    <img src="assets/img/home-one/history-main.jpg" alt="History">
                                </div>
                            </div>

                            <div class="col-lg-7 col-md-12">
                                <div class="history-content">
                                    <h2>Let’s  make  great  things  together!</h2>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                    <div class="row">
                                        <div class="col-sm-4 col-lg-4">
                                            <div class="history-inner">
                                                <i class='flaticon-video-camera'></i>
                                                <h3>FILM  PRODuction</h3>
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</span>
                                            </div>
                                        </div>

                                        <div class="col-sm-4 col-lg-4">
                                            <div class="history-inner">
                                                <i class='flaticon-creative-idea'></i>
                                                <h3>Creative Direction</h3>
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</span>
                                            </div>
                                        </div>

                                        <div class="col-sm-4 col-lg-4">
                                            <div class="history-inner">
                                                <i class='flaticon-eye'></i>
                                                <h3>Visual Effects</h3>
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
        
                    <li data-date="01/01/2012">
                        <div class="row align-items-center m-0">
                            <div class="col-lg-5 col-md-12">
                                <div class="history-img">
                                    <img src="assets/img/home-one/history-main.jpg" alt="History">
                                </div>
                            </div>

                            <div class="col-lg-7 col-md-12">
                                <div class="history-content">
                                    <h2>Let’s  make  great  things  together!</h2>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                    <div class="row">
                                        <div class="col-sm-4 col-lg-4">
                                            <div class="history-inner">
                                                <i class='flaticon-video-camera'></i>
                                                <h3>FILM  PRODuction</h3>
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</span>
                                            </div>
                                        </div>

                                        <div class="col-sm-4 col-lg-4">
                                            <div class="history-inner">
                                                <i class='flaticon-creative-idea'></i>
                                                <h3>Creative Direction</h3>
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</span>
                                            </div>
                                        </div>

                                        <div class="col-sm-4 col-lg-4">
                                            <div class="history-inner">
                                                <i class='flaticon-eye'></i>
                                                <h3>Visual Effects</h3>
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
        
                    <li data-date="01/01/2013">
                        <div class="row align-items-center m-0">
                            <div class="col-lg-5 col-md-12">
                                <div class="history-img">
                                    <img src="assets/img/home-one/history-main.jpg" alt="History">
                                </div>
                            </div>

                            <div class="col-lg-7 col-md-12">
                                <div class="history-content">
                                    <h2>Let’s  make  great  things  together!</h2>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                    <div class="row">
                                        <div class="col-sm-4 col-lg-4">
                                            <div class="history-inner">
                                                <i class='flaticon-video-camera'></i>
                                                <h3>FILM  PRODuction</h3>
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</span>
                                            </div>
                                        </div>

                                        <div class="col-sm-4 col-lg-4">
                                            <div class="history-inner">
                                                <i class='flaticon-creative-idea'></i>
                                                <h3>Creative Direction</h3>
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</span>
                                            </div>
                                        </div>

                                        <div class="col-sm-4 col-lg-4">
                                            <div class="history-inner">
                                                <i class='flaticon-eye'></i>
                                                <h3>Visual Effects</h3>
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>