<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Blog</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><span></span></li>
                <li>Blog</li>
            </ul>
        </div>
    </div>
</div>

<section class="blog-area blog-area-two pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <a routerLink="/blog-details"><img src="assets/img/home-one/blog1.jpg" alt="Blog"></a>
                    <div class="blog-bottom">
                        <span>17 June 2020</span>
                        <h3><a routerLink="/blog-details">Get Ready To Make Awesome</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a class="blog-btn" routerLink="/blog-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <a routerLink="/blog-details"><img src="assets/img/home-one/blog2.jpg" alt="Blog"></a>
                    <div class="blog-bottom">
                        <span>18 June 2020</span>
                        <h3><a routerLink="/blog-details">The Best Design Film</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a class="blog-btn" routerLink="/blog-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <a routerLink="/blog-details"><img src="assets/img/home-one/blog3.jpg" alt="Blog"></a>
                    <div class="blog-bottom">
                        <span>19 June 2020</span>
                        <h3><a routerLink="/blog-details">Enjoy The Movie Drama</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a class="blog-btn" routerLink="/blog-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <a routerLink="/blog-details"><img src="assets/img/home-one/blog4.jpg" alt="Blog"></a>
                    <div class="blog-bottom">
                        <span>20 June 2020</span>
                        <h3><a routerLink="/blog-details">It Has Been Most Watched</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a class="blog-btn" routerLink="/blog-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <a routerLink="/blog-details"><img src="assets/img/home-one/blog5.jpg" alt="Blog"></a>
                    <div class="blog-bottom">
                        <span>21 June 2020</span>
                        <h3><a routerLink="/blog-details">The Movie Has Been Awarded</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a class="blog-btn" routerLink="/blog-details">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <a routerLink="/blog-details"><img src="assets/img/home-one/blog6.jpg" alt="Blog"></a>
                    <div class="blog-bottom">
                        <span>22 June 2020</span>
                        <h3><a routerLink="/blog-details">Watch The Best Film</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a class="blog-btn" routerLink="/blog-details">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>