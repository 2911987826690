<div class="page-title-area">
    <div class="container">
        <div class="page-title-item">
            <h2>Contact</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><span></span></li>
                <li>Contact</li>
            </ul>
        </div>
    </div>
</div>

<div class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-item">
                    <h2>Get In Touch</h2>

                    <form id="contactForm">
                        <div class="row">
                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Phone">
                                </div>
                            </div>
    
                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="8" placeholder="Message"></textarea>
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12">
                                <button type="submit" class="contact-btn btn">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="contact-item">
                    <h2>Know More</h2>

                    <div class="contact-address">
                        <div class="contact-inner">
                            <i class='bx bx-current-location icon'></i>
                            <h4>Address:</h4>
                            <span>3254-425 NW-2nd Ave, Miami USA</span>
                        </div>

                        <div class="contact-inner">
                            <i class='bx bx-phone-call icon'></i>
                            <h4>Phone:</h4>
                            <a href="tel:882569756">882-569-756</a>
                        </div>

                        <div class="contact-inner">
                            <i class='bx bx-mail-send icon'></i>
                            <h4>Email:</h4>
                            <a href="mailto:info@zico.com">info@zico.com</a>
                        </div>

                        <div class="contact-inner">
                            <i class='bx bx-support icon'></i>
                            <h4>Support:</h4>
                            
                            <ul>
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="map-area">
    <iframe id="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60713147.49322739!2d62.88250195139032!3d21.755615757913848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3663f18a24cbe857%3A0xa9416bfcd3a0f459!2sAsia!5e0!3m2!1sen!2sbd!4v1594194977692!5m2!1sen!2sbd"></iframe>
</div>