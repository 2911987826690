<div class="page-title-area page-title-area-two">
    <div class="container">
        <div class="page-title-item">
            <h2>Adlock Prince</h2>
            <ul>
                <li>Actor</li>
                <li><span></span></li>
                <li>Director</li>
                <li><span></span></li>
                <li>Film Maker</li>
            </ul>
        </div>
    </div>
</div>

<div class="about-me-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-me-img">
                    <img src="assets/img/about-me2.jpg" alt="About">
                </div>
            </div>
            
            <div class="col-lg-7 col-md-12">
                <div class="about-me-content">
                    <h2>Adlock Prince</h2>
                    <ul>
                        <li>Actor</li>
                        <li>Director</li>
                        <li>Film Maker</li>
                    </ul>

                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolore sint maxime qui necessitatibus doloribus natus repudiandae error iure culpa, quos, suscipit facere sapiente dolorem sit consequuntur sed libero? Nostrum, corrupti.</p>

                    <div class="row">
                        <div class="col-sm-6 col-lg-6">
                            <div class="about-me-inner">
                                <i class='bx bx-video-recording'></i>
                                <h3>Film Production</h3>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Error explicabo consequatur iste facilis.</p>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="about-me-inner">
                                <i class='bx bx-video-plus'></i>
                                <h3>New Technologies</h3>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Error explicabo consequatur iste facilis.</p>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="about-me-inner">
                                <i class='bx bx-user'></i>
                                <h3>Top Directions</h3>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Error explicabo consequatur iste facilis.</p>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="about-me-inner">
                                <i class='bx bx-directions'></i>
                                <h3>Distribution</h3>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Error explicabo consequatur iste facilis.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="trust-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Who I Am</span>
            <h2>Awards</h2>
        </div>

        <div class="trust-content">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

            <div class="row">
                <div class="col-6 col-sm-3 col-lg-3">
                    <div class="trust-img">
                        <img src="assets/img/home-one/trust1.png" alt="Trust">
                    </div>
                </div>

                <div class="col-6 col-sm-3 col-lg-3">
                    <div class="trust-img">
                        <img src="assets/img/home-one/trust2.png" alt="Trust">
                    </div>
                </div>

                <div class="col-6 col-sm-3 col-lg-3">
                    <div class="trust-img">
                        <img src="assets/img/home-one/trust3.png" alt="Trust">
                    </div>
                </div>

                <div class="col-6 col-sm-3 col-lg-3">
                    <div class="trust-img">
                        <img src="assets/img/home-one/trust4.png" alt="Trust">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="work-area work-area-two work-area-three pt-100 pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <span class="sub-title">Work</span>
            <h2>My Featured Work</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-6">
                <a routerLink="/works-details">
                    <div class="work-item">
                        <img src="assets/img/home-one/work1.jpg" alt="Work">
                        <div class="work-content">
                            <h3>My Dream Stranger</h3>
                            <span>Music ViDEO</span>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-sm-6 col-lg-6">
                <a routerLink="/works-details">
                    <div class="work-item">
                        <img src="assets/img/home-one/work2.jpg" alt="Work">
                        <div class="work-content">
                            <h3>Back To You</h3>
                            <span>Advertising Music Video</span>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-sm-6 col-lg-6">
                <a routerLink="/works-details">
                    <div class="work-item">
                        <img src="assets/img/home-one/work3.jpg" alt="Work">
                        <div class="work-content">
                            <h3>We Make it awesome</h3>
                            <span>Commercial Music Video</span>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col-sm-6 col-lg-6">
                <a routerLink="/works-details">
                    <div class="work-item">
                        <img src="assets/img/home-one/work4.jpg" alt="Work">
                        <div class="work-content">
                            <h3>My Dream Girl</h3>
                            <span>Commercial Music Video</span>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>